<template>
  <div>
    <div class="page-heading">
      <h1 class="h3">Elite Videos</h1>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="(video, i) in videos" :key="`video-${i}`">
        <div class="card">
          <router-link
            :to="{ name: 'moderator.elite-videos.show', params: { id: video.id } }"
            >
            <img :src="video.thumbnail" alt="thumbnail"> 
          </router-link>
          <div class="px-3 py-2">
            <p class="mb-0">
              <router-link
                :to="{
                  name: 'moderator.elite-videos.show',
                  params: { id: video.id },
                }"
                class="text-black d-block"
                >{{ video.title }}</router-link
              >
            </p>
            <p class="mb-0">
              <small class="text-muted">{{ video.date }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      videos: [],
      loading: true
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.loading = true

      this.$axios.get("/api/v1/moderator/elite-videos").then((response) => {
        this.videos = response.data.videos;
        this.loading = false
        this.$loader.stop();
      });
    },
  },
};
</script>